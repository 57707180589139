import { DataCapsule, LocalStorageStrategy } from 'data-capsule';
import { LightboxItem } from '../../interfaces';

export class LightboxStorage {
  public capsule?: any;
  private readonly location?: string;
  private readonly item?: string;

  constructor(location: string) {
    this.capsule = new DataCapsule({
      strategy: new LocalStorageStrategy(),
      namespace: 'wixLightboxStorage',
    });
    this.location = location;
    this.item = `lightboxes|${this.location}`;
  }

  async getLightboxes() {
    try {
      return (await this.capsule.getItem(this.item)) || [];
    } catch {
      await this.capsule.setItem(this.item, []);
      return [];
    }
  }

  async saveLightbox(lightbox: LightboxItem) {
    const lightboxes = await this.getLightboxes();
    const updatedLightboxes = lightboxes.filter(
      (lb: LightboxItem) => lb.lightboxGuid !== lightbox.lightboxGuid,
    );
    return this.capsule.setItem(this.item, [...updatedLightboxes, lightbox]);
  }
}
