import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { LightboxResolver } from '../services/LightboxResolver';
import { LightboxStorage } from '../services/LightboxStorage';
import { LazyLightbox } from '../lazy/LazyLightbox';
import { BannerData } from '@wix/dealer-common/dist/src/interfaces';
import { create as fedopsLoggerFactory } from '@wix/fedops-logger';
import { FedopsInteractions } from '@wix/dealer-client-api/dist/src/DealerApi';
import { ServerApi } from '@wix/dealer-client-api/dist/src/ServerApi';

export interface DealerLightboxApiProps {
  translationsUrl: string;
  dealerOptions?: any;
  locale?: string;
  onView?: Function;
  onClick?: Function;
  onClose?: Function;
  location: string;
  metasiteGuid?: string;
  viewerUrl: string;
}

export class DealerLightboxApi {
  async initLightbox(dealerLightboxApiProps: DealerLightboxApiProps) {
    const { location, metasiteGuid } = dealerLightboxApiProps;
    const lightboxStorage = new LightboxStorage(location);
    const fedopsLogger = fedopsLoggerFactory('dealer-lightbox');
    const lightboxResolver = new LightboxResolver(lightboxStorage);
    const excludeList = await lightboxResolver.calculateExcludedLightboxes();
    fedopsLogger.interactionStarted(FedopsInteractions.LOAD_ASSET);
    let serverLightbox: BannerData;
    try {
      serverLightbox = await this.getDealerData(
        location,
        metasiteGuid,
        excludeList,
      );
      const lightbox = lightboxResolver.getLightbox(serverLightbox);
      if (lightbox) {
        this.renderLightbox(dealerLightboxApiProps, lightbox, lightboxStorage);
      }
    } catch {
      serverLightbox = null;
    } finally {
      fedopsLogger.interactionEnded(FedopsInteractions.LOAD_ASSET);
    }
  }

  private getDealerData(
    location: string,
    metasiteGuid: string,
    excludeList: any[],
  ) {
    return ServerApi.getDealerData({
      bannerPositions: [location],
      excludeLightboxes: excludeList,
      metasiteGuid,
    });
  }

  private readonly renderLightbox = (
    props: DealerLightboxApiProps,
    lightbox: BannerData,
    lightboxStorage: LightboxStorage,
  ) => {
    ReactDOM.render(
      <LazyLightbox
        bannerPosition={props.location}
        locale={props.locale}
        metasiteGuid={props.metasiteGuid || ''}
        translationsUrl={props.translationsUrl}
        dealerOptions={props.dealerOptions}
        onView={props.onView}
        onClose={props.onClose}
        onClick={props.onClick}
        lightbox={lightbox}
        viewerUrl={props.viewerUrl}
        lightboxStorage={lightboxStorage}
      />,
      DealerLightboxApi.createLightboxContainerOnBody(),
    );
  };

  static createLightboxContainerOnBody() {
    const styles = {
      position: 'absolute',
      ['z-index']: '99999999',
    };
    const dealerDiv = document.createElement('div');
    dealerDiv.setAttribute('id', 'react-lightbox');
    Object.assign(dealerDiv.style, styles);
    const body = document.querySelector('body');
    body.appendChild(dealerDiv);
    return dealerDiv;
  }
}
