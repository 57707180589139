import { LightboxStorage } from '../LightboxStorage';
import { LightboxItem, LightboxTriggers } from '../../interfaces';
import { BannerData } from '@wix/dealer-common/dist/src/interfaces';

export class LightboxResolver {
  public triggers: LightboxTriggers;
  public storageInstance: LightboxStorage;
  public lightboxes: LightboxItem[];

  constructor(lightboxStorage: LightboxStorage) {
    this.triggers = null;
    this.storageInstance = lightboxStorage;
  }

  getLightbox(serverLightbox: BannerData) {
    if (this.isLightboxAlreadyInLightboxes(serverLightbox)) {
      return serverLightbox;
    }
    const { ttlSecAnyLB } = JSON.parse(serverLightbox.lightboxTriggers);
    return this.lightboxes.some((lb: LightboxItem) =>
      this.filterTTLAnyFromServer(lb, ttlSecAnyLB),
    )
      ? null
      : serverLightbox;
  }

  async calculateExcludedLightboxes() {
    this.lightboxes = await this.loadLightboxesFromStorage();
    const excludedTTLSameLB = this.lightboxes.filter(this.filterTTLSameLB);
    const excludedTTLAnyLB: LightboxItem[] = this.excludeAny();
    return [...excludedTTLSameLB, ...excludedTTLAnyLB].map(this.getLightboxId);
  }

  private loadLightboxesFromStorage() {
    return this.storageInstance.getLightboxes();
  }

  private excludeAny() {
    const excludeAny: LightboxItem[] = [];
    this.lightboxes.forEach((lbItem: LightboxItem) => {
      if (
        this.lightboxes
          .filter(item => lbItem.lightboxGuid !== item.lightboxGuid)
          .some(
            item =>
              item.lastSeen > lbItem.lastSeen &&
              item.lastSeen < lbItem.lastSeen + lbItem.ttlSecAnyLB,
          )
      ) {
        excludeAny.push(lbItem);
      }
    });
    return excludeAny;
  }

  private readonly isLightboxAlreadyInLightboxes = (
    serverLightbox: BannerData,
  ) =>
    this.lightboxes.filter(lb => lb.lightboxGuid === serverLightbox.bannerId)
      .length > 0;

  private readonly getLightboxId = (item: LightboxItem): string =>
    item.lightboxGuid;

  private readonly filterTTLSameLB = (lightbox: LightboxItem): boolean =>
    lightbox.lastSeen + lightbox.ttlSecSameLB * 1000 > Date.now();

  private readonly filterTTLAnyFromServer = (
    lb: LightboxItem,
    ttlSecAnyLB: number,
  ) => lb.lastSeen + ttlSecAnyLB * 1000 > Date.now();
}
